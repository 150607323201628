<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row">
              <div class="col-md-3 text-black">
                <strong class="fs-16px">
                  {{ $t("Email address") }}
                </strong>
              </div>
              <div class="col-md-2 text-black">
                <strong class="fs-16px">
                  {{ $t("Invited at") }}
                </strong>
              </div>
              <div class="col-md-1 text-black">
                <strong class="fs-16px">
                  {{ $t("Status") }}
                </strong>
              </div>
              <div class="col-md-5 text-black">
                <strong class="fs-16px">
                  {{ $t("Listing") }}
                </strong>
              </div>
            </div>
          </div>
          <div class="card-body pb-5">
            <div class="d-flex" v-if="loading">
              <b-spinner large label="Spinning" class="text-muted text-center m-auto">
              </b-spinner>
            </div>
            <div
              class="text-muted text-center fs-24 mt-3"
              v-if="!loading && (!invites || !invites.data || invites.data.length == 0)"
            >
              {{ $t("No invites found") }}
            </div>
            <div
              class="row border-bottom"
              v-for="(invite, index) in invites.data"
              v-bind:key="index"
            >
              <div class="col-md-3">
                <strong class="fs-16px">
                  {{ invite.email }}
                </strong>
              </div>
              <div class="col-md-2">
                <div class="text-black fs-16px">
                  {{ invite.created_at | moment("DD-MM-YYYY") }}
                </div>
              </div>
              <div class="col-md-1">
                <span class="badge badge-primary p-2" v-if="invite.user">Registered</span>
                <span class="badge badge-light-gray p-2" v-else>Invited</span>
              </div>
              <div class="col-md-5">
                <div class="text-black fs-16px">
                  {{ invite.listing.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4" v-if="invites.total">
      <div class="col-md-12">
        <div class="text-black d-flex justify-content-between">
          {{ invites.from }} - {{ invites.to }} of {{ invites.total }}
          <div class="d-flex align-items-center">
            {{ invites.current_page }} of {{ invites.last_page }}

            <button
              class="btn bg-white arrowBtn"
              :disabled="!invites.prev_page_url"
              @click="getInvites(invites.prev_page_url)"
            >
              <i class="mdi mdi-chevron-left"></i>
            </button>
            <button
              class="btn bg-white arrowBtn"
              :disabled="!invites.next_page_url"
              @click="getInvites(invites.next_page_url)"
            >
              <i class="mdi mdi-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invites: {},
      loading: false,
      error: "",
    };
  },
  mounted() {
    this.getInvites();
  },
  methods: {
    getInvites(url = "") {
      this.invites = {};
      this.loading = true;
      this.error = "";
      url = url ? url : "/broker/invites";
      window.axios
        .get(url)
        .then((response) => {
          const { data } = response;
          this.invites = data.invites;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message;
        });
    },
  },
};
</script>
