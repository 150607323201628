<template>
  <div class="landlord container">
    <div class="row">
      <div class="col-md-12">
        <h4 class="fw-bold heading mb-1 fs-2rem">{{ $t("contacts") }}</h4>
        Automaticly invite contacts? Forward your Funda emails to:
        <span class="text-info"> broker-{{ user.token }}@parse.eerlijktehuur.nl </span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-tabs content-class="mt-3" active-nav-item-class="">
          <!-- This tabs content will always be mounted -->
          <b-tab title="Candidates invitation">
            <CandidateInvitations />
          </b-tab>

          <!-- This tabs content will not be mounted until the tab is shown -->
          <!-- and will be un-mounted when hidden -->
          <b-tab title="Landlords" lazy>
            <div class="row">
              <div class="col-md-12">
                <div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card">
                        <div class="card-header bg-white">
                          <div class="row">
                            <div class="col-md-2 text-black">
                              <strong class="fs-16px">
                                {{ $t("name") }}
                              </strong>
                            </div>
                            <div class="col-md-4 text-black">
                              <strong class="fs-16px">
                                {{ $t("email_address") }}
                              </strong>
                            </div>
                            <div class="col-md-6 text-black">
                              <strong class="fs-16px">
                                {{ $t("listings") }}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div class="card-body pb-5">
                          <div class="d-flex" v-if="loading">
                            <b-spinner
                              large
                              label="Spinning"
                              class="text-muted text-center m-auto"
                            >
                            </b-spinner>
                          </div>
                          <div
                            class="text-muted text-center fs-24 mt-3"
                            v-if="
                              !loading &&
                              (!contacts || !contacts.data || contacts.data.length == 0)
                            "
                          >
                            {{ $t("no_contact_found") }}
                          </div>
                          <div
                            class="row border-bottom"
                            v-for="(contact, index) in contacts.data"
                            v-bind:key="index"
                          >
                            <div class="col-md-2">
                              <strong class="fs-16px">
                                {{ contact.first_name }} {{ contact.last_name }}
                              </strong>
                            </div>
                            <div class="col-md-4">
                              <div class="text-black fs-16px">
                                {{ contact.email }}
                              </div>
                            </div>
                            <div class="col-md-4" v-if="contact.listings">
                              <div class="text-black fs-16px">
                                {{ contact.listings.map((l) => `${l.title}`).join(", ") }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4" v-if="contacts.total">
                    <div class="col-md-12">
                      <div class="text-black d-flex justify-content-between">
                        {{ contacts.from }} - {{ contacts.to }} of {{ contacts.total }}
                        <div class="d-flex align-items-center">
                          {{ contacts.current_page }} of {{ contacts.last_page }}

                          <button
                            class="btn bg-white arrowBtn"
                            :disabled="!contacts.prev_page_url"
                            @click="getLandlords(contacts.prev_page_url)"
                          >
                            <i class="mdi mdi-chevron-left"></i>
                          </button>
                          <button
                            class="btn bg-white arrowBtn"
                            :disabled="!contacts.next_page_url"
                            @click="getLandlords(contacts.next_page_url)"
                          >
                            <i class="mdi mdi-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <div class="alert alert-danger" role="alert" v-if="error">{{ error }}</div>
    <!-- filters -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CandidateInvitations from "../CandidateInvitations.vue";

export default {
  name: "ContactsIndex",
  components: { CandidateInvitations },
  data() {
    return {
      contacts: {},
      loading: false,
      error: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["userType", "user", "isAuth"]),
  },
  mounted() {
    this.getLandlords();
  },
  methods: {
    getLandlords(url = "") {
      this.contacts = {};
      this.loading = true;
      this.error = "";
      url = url ? url : "/broker/landlords";
      window.axios
        .get(url)
        .then((response) => {
          const { data } = response;
          this.contacts = data.landlords;
          this.loading = false;
        })
        .catch((error) => {
          this.error = error?.response?.data?.message
            ? error?.response?.data?.message
            : error.message;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.arrows {
  width: 32px;
  height: 32px;
  display: flex;
  border: 1px solid #c9c9c9;
  margin-left: 1rem;
  background-color: #fff;
  border-radius: 50%;
  cursor: pointer;

  i {
    margin: auto;
  }
}

.arrowBtn {
  width: 32px !important;
  height: 32px !important;
  border: 1px solid #c9c9c9;
  padding: 0px !important;
  border-radius: 50%;
  margin-left: 1rem;

  .disabled {
    background-color: #e6e6e6;
  }
}
</style>
